import { FirebaseFirestore, SuggestionType } from '@innedit/innedit';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import IconEye from '../../../../../../icons/Eye';
import IconEyeSlash from '../../../../../../icons/EyeSlash';
import ListItem from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const Item: FC<ListItemProps<SuggestionType>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<SuggestionType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const data = doc.data() as SuggestionType;

  return (
    <ListItem
      actions={{ tooltip: doc.id, type: 'tooltip' }}
      className={classnames(className)}
      contentClassName="flex-auto flex flex-row"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <div className="ml-3 flex items-center space-x-3">
        {!data.hidden && <IconEye />}
        {data.hidden && <IconEyeSlash />}
        <strong>{data.libelle}</strong>
      </div>
    </ListItem>
  );
};

export default Item;
