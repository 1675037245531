import { SuggestionData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../core/sysext/Boutique/containers/Suggestion/components/List/Item';
import params from '../../core/sysext/Boutique/containers/Suggestion/params.json';
import CMSView from '../../core/sysext/CMS/components/View';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import List from '../../core/sysext/CMS/containers/Boutique/components/new/List';

const SuggestionsPage: FC<PageProps> = props => {
  const { location } = props;
  const { t } = useTranslation();

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new SuggestionData({
    boutique,
    params,
    orderDirection: 'asc',
    orderField: 'libelle',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        <List
          {...props}
          formName="suggestion"
          item={Item}
          itemPathnamePrefix="/boutique/suggestions/"
          model={model}
          nbParPage={50}
          title={t('suggestions')}
        />
      </CMSView>
    </Backoffice>
  );
};

export default SuggestionsPage;
